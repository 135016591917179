import * as history from 'history';
import {
  RootLocationTransformer,
  createRootLocationForOtherConsumer,
  createRootLocationForPrimaryConsumer,
  createSearchParams,
  getConsumerPath,
  serializeSearchParams,
} from '@feature-hub/history-service';

const consumerPathsQueryParamName = 'consumerLocations';

function getHashLocation(
  rootLocation: Partial<history.Location>,
): Partial<history.Location> {
  return history.parsePath(
    rootLocation.hash ? rootLocation.hash.substring(1) : '/',
  );
}

/**
 * The resulting RootLocationTransformer does exactly the same as the default RootLocationTransformer except all
 * consumer locations go into the hash
 *
 * @param  primaryConsumerHistoryKeys Array of featureAppId of the primary consumer
 *                                   (aka the feature app that should have a nice and readable path)
 */
export function createRootLocationTransformer(
  integratorId: string,
  primaryConsumerHistoryKeys: string[] | undefined,
): RootLocationTransformer {
  return {
    createRootLocation: (
      currentRootLocation: Partial<history.Location>,
      consumerLocation: Partial<history.Location>,
      historyKey: string,
    ): Partial<history.Path> => {
      if (historyKey === integratorId) {
        return {
          ...consumerLocation,
          hash: currentRootLocation.hash,
        };
      }
      const consumerRootLocation = getHashLocation(currentRootLocation);
      const isPrimaryConsumer =
        primaryConsumerHistoryKeys.indexOf(historyKey) !== -1;

      let hashLocation: Partial<history.Path>;
      if (isPrimaryConsumer) {
        hashLocation = createRootLocationForPrimaryConsumer(
          consumerRootLocation,
          consumerLocation,
          consumerPathsQueryParamName,
        );
      } else {
        hashLocation = createRootLocationForOtherConsumer(
          consumerRootLocation,
          consumerLocation,
          historyKey,
          consumerPathsQueryParamName,
        );
      }

      return {
        ...currentRootLocation,
        hash: `#${history.createPath(hashLocation)}`,
      };
    },

    getConsumerPathFromRootLocation: (
      rootLocation: Partial<history.Location>,
      historyKey: string,
    ): string | undefined => {
      if (historyKey === integratorId) {
        return history.createPath({
          ...rootLocation,
          hash: undefined,
        });
      }

      rootLocation = getHashLocation(rootLocation);

      const isPrimaryConsumer =
        primaryConsumerHistoryKeys.indexOf(historyKey) !== -1;
      const searchParams = createSearchParams(rootLocation);

      if (isPrimaryConsumer) {
        searchParams.delete(consumerPathsQueryParamName);

        const { pathname, hash } = rootLocation;
        const search = serializeSearchParams(searchParams);

        return history.createPath({
          hash,
          pathname,
          search,
        });
      }

      const consumerPaths = searchParams.get(consumerPathsQueryParamName);

      if (!consumerPaths) {
        return undefined;
      }

      return getConsumerPath(consumerPaths, historyKey);
    },
  };
}
