import { AuthServiceProviderV2 } from '@oneaudi/audi-auth-service';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { HistoryServiceV2 } from '@feature-hub/history-service';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';

export interface IntegratorFeatureServices {
  'audi-content-service': ContentServiceV1;
  's2:history': HistoryServiceV2;
  's2:serialized-state-manager': SerializedStateManagerV1;
  'vw:authService': AuthServiceProviderV2;
}

export const featureServiceDependencies: Record<
  keyof IntegratorFeatureServices,
  string
> = {
  'audi-content-service': '^1.0.0',
  's2:history': '^2.0.0',
  's2:serialized-state-manager': '^1.0.0',
  'vw:authService': '^2.0.0',
};
