import {
  ConsentManager,
  ConsentManagerConsent,
} from '@oneaudi/audi-cookie-service';
import { createLogger } from '../logger';

export function createFallbackConsentManager(): ConsentManager {
  const logger = createLogger();
  logger.warn(
    'CookieService is using Fallback consent manager and will a return a negative consent by default',
  );
  const getConsent = (): ConsentManagerConsent => {
    return {
      essential: false,
      functional: false,
      marketing: false,
      performance: false,
    };
  };

  return new ConsentManager(getConsent);
}
