import {
  FeatureAppManager,
  FeatureServiceConsumerDefinition,
  FeatureServiceRegistry,
  Logger,
} from '@feature-hub/core';

import {
  IntegratorFeatureServices,
  featureServiceDependencies,
} from './feature-hub/service-dependencies';

import {
  applyPreEosImageHandshake,
  requiresEosImageHandshake,
} from './internal/pre-eos-image-author-preview';
import { defineExternals, loadAmdModule } from '@feature-hub/module-loader-amd';
import {
  definedExternals,
  providedExternals,
} from './feature-hub/provided-externals';

import { ContentStore } from '@oneaudi/content-service';
import { DebugListener } from './debug-extension/debug-listener';
import { FeatureAppIntegrator } from './feature-apps/feature-app-integrator';
import { INTEGRATOR_ID } from './constants';
import { LoggingExternalsValidator } from './feature-hub/logging-externals-validator';
import { activateHistoryServiceForNavigation } from './feature-services/feature-service-history';
import { createFeatureServiceDefinitions } from './feature-hub/service-definitions';
import { createLogger } from './logger';
import { processAuthService } from './feature-services/feature-service-auth';
import { processSerializedStates } from './feature-services/feature-service-serialized-states';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface StateRegistry {
    addStore: (
      arg0: string,
      arg1: Record<string, unknown>,
      arg2: Record<string, unknown>,
    ) => void;
  }
  interface Microkernel {
    stateRegistry: StateRegistry;
  }
  interface Window {
    microkernel: Microkernel;
  }
}

const PAGE_LOADED = 'PAGE_LOADED';
const LAYER_LOADED = 'LAYER_LOADED';
const CONTENT_RENDERED = 'content:rendered';

const __setupIntegrator = (): {
  contentStore: ContentStore;
  featureAppManager: FeatureAppManager;
  featureServices: IntegratorFeatureServices;
  logger: Logger;
} => {
  defineExternals(definedExternals);

  const logger = createLogger();

  const externalsValidator = new LoggingExternalsValidator(
    providedExternals,
    logger,
  );

  const featureServiceRegistry = new FeatureServiceRegistry({
    externalsValidator,
    logger,
  });

  const integratorDefinition: FeatureServiceConsumerDefinition = {
    dependencies: { featureServices: featureServiceDependencies },
  };

  const { featureServiceDefinitions: featureServiceDefinitions, contentStore } =
    createFeatureServiceDefinitions(logger);

  featureServiceRegistry.registerFeatureServices(
    featureServiceDefinitions,
    INTEGRATOR_ID,
  );

  const featureAppManager = new FeatureAppManager(featureServiceRegistry, {
    externalsValidator,
    logger,
    moduleLoader: loadAmdModule,
  });

  const { featureServices } = featureServiceRegistry.bindFeatureServices(
    integratorDefinition,
    INTEGRATOR_ID,
  );

  // Initialize debug extension //
  new DebugListener(featureAppManager, featureServices);
  //  //

  (window.audiIntegrator ||= {}).printFeatureHubInfo = (): void => {
    /* eslint-disable no-console */
    console.group('Feature Services');
    console.table(
      featureServiceRegistry
        .getInfo()
        .featureServices.map(({ id, versions }) => ({
          id,
          versions: versions.join(', '),
        })),
    );
    console.groupEnd();

    console.group('Externals');
    console.table(
      Object.entries(providedExternals).map(([name, version]) => ({
        name,
        version,
      })),
    );
    console.groupEnd();
    /* eslint-enable no-console */
  };

  return {
    contentStore,
    featureAppManager,
    featureServices: featureServices as unknown as IntegratorFeatureServices,
    logger,
  };
};

const __processFeatureServices = (
  featureServices: IntegratorFeatureServices,
): void => {
  const serializedStateManager = featureServices['s2:serialized-state-manager'];
  processSerializedStates(serializedStateManager);

  const authServiceProvider = featureServices['vw:authService'];
  const authService = authServiceProvider.register('myaudi');
  processAuthService(authService);

  const historyService = featureServices['s2:history'];
  activateHistoryServiceForNavigation(historyService);
};

const __applyFeatureHub = async (
  event: CustomEvent,
  featureAppIntegrator: FeatureAppIntegrator,
): Promise<void> => {
  if (
    event &&
    event.type &&
    (event.type === PAGE_LOADED ||
      event.type === LAYER_LOADED ||
      event.type === CONTENT_RENDERED)
  ) {
    if (event.detail && event.detail.element) {
      await featureAppIntegrator.initFeatureApps(event.detail.element);
    }
  }
};

const __integrateFeatureApps = async (): Promise<void> => {
  const { contentStore, featureAppManager, featureServices } =
    exportedObject.__setupIntegrator();

  exportedObject.__processFeatureServices(featureServices);

  const featureAppIntegrator = new FeatureAppIntegrator(
    featureAppManager,
    featureServices,
    contentStore,
  );

  const applyFeatureHubToPartial = (event: CustomEvent): void => {
    exportedObject.__applyFeatureHub(event, featureAppIntegrator);
  };

  document.addEventListener(PAGE_LOADED, applyFeatureHubToPartial);
  document.addEventListener(LAYER_LOADED, applyFeatureHubToPartial);
  document.addEventListener(CONTENT_RENDERED, applyFeatureHubToPartial);

  setTimeout(async () => {
    if (requiresEosImageHandshake(document.head)) {
      const logger = createLogger();
      await applyPreEosImageHandshake(document.head, logger);
    }
    await featureAppIntegrator.initFeatureApps(document.body);
  });
};

const exportedObject = {
  __applyFeatureHub,
  __integrateFeatureApps,
  __processFeatureServices,
  __setupIntegrator,
};

exportedObject.__integrateFeatureApps().catch(console.error);

export default exportedObject;
export { FeatureServiceConfigOverride } from './feature-hub/service-definitions';
