import { HistoryServiceV2 } from '@feature-hub/history-service';
import { NavigationServiceOptions } from '@volkswagen-onehub/navigation-service';
import { getNavigationOptions } from '../feature-hub/service-configuration';

const newLocationIsRegisteredTarget = function (
  navigationServiceOptions: NavigationServiceOptions,
  newLocation: string,
): boolean {
  if (navigationServiceOptions && navigationServiceOptions.pages) {
    for (const [key, value] of Object.entries(navigationServiceOptions.pages)) {
      if (key && value && value.path && value.path === newLocation) {
        return true;
      }
    }
  }

  return false;
};

const activateHistoryServiceForNavigation = function (
  historyService: HistoryServiceV2,
): void {
  let lastPath = window.location.pathname;

  const navServiceOptions = getNavigationOptions();

  historyService.rootHistory.listen(() => {
    if (
      newLocationIsRegisteredTarget(
        navServiceOptions,
        window.location.pathname,
      ) &&
      window.location.pathname !== lastPath
    ) {
      window.scrollTo(0, 0);
      location.reload();
    }
    lastPath = window.location.pathname;
  });
};

export {
  activateHistoryServiceForNavigation,
  newLocationIsRegisteredTarget as __newLocationIsRegisteredTarget,
};
