import { Logger } from '@feature-hub/logger';
import { createVinByRegExpRemover } from '@oneaudi/audi-tracking-service';

/**
 * This method will replace the GraphQl relay IDs used by myAudi with the specific place holder.
 * Normaly the base64 encoded ID will result in something like Vehicle:xxx
 * where Vehicle would be the id type. This will be used as the replacement string.
 *
 * @param url
 * @returns
 */
function replaceRelayIdsInUrl(url: string): string {
  if (!url) {
    return '';
  }

  return url
    .split('/')
    .map((pathPart) => {
      const decoded = Buffer.from(pathPart, 'base64')
        .toString('utf-8')
        .split(':');

      if (decoded && decoded.length > 1 && decoded[0].match(/^\w+$/)) {
        return `${decoded[0].toLowerCase()}-id`;
      }
      return pathPart;
    })
    .join('/');
}

export function removePrivateData(
  url: string,
  logger: Logger = console,
): string {
  const replaceVinInUrl = createVinByRegExpRemover(logger);
  let newUrl = url;
  newUrl = newUrl.replace(
    /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i,
    'UUID',
  );
  newUrl = newUrl.replace(
    /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}/,
    'EMAIL',
  );
  newUrl = replaceRelayIdsInUrl(newUrl);
  newUrl = replaceVinInUrl(newUrl);

  return newUrl;
}
